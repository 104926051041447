import { PropsWithChildren, useEffect } from "react";
import ApplicationLogo from "@/Components/ApplicationLogo";
import { Icon, InlineIcon } from "@iconify/react";
import { Link } from "@inertiajs/react";
import NavMenu from "@/Components/NavMenu/NavMenu";
import { useState } from "react";
import { MouseEvent } from "react";
import Container from "@/Components/Backend/Grid/Container";
import Row from "@/Components/Backend/Grid/Row";
import Column from "@/Components/Backend/Grid/Column";
import LinksArrayFront from "@/Components/NavMenu/LinksArrayFront";
import './main_layout.scss';
import Footer from "@/Components/Front/Footer";
import LinksArrayFrontDesktop from "@/Components/NavMenu/LinksArrayFrontDesktop";
import Text from "@/Components/Front/Text";
import { MenuLinks } from "@/types/components/navMenu/navMenu";

const MainLayout = ({ children, disableNav = false }: PropsWithChildren<{ disableNav?: boolean }>) => {

  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)

  const showNav = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setIsMenuVisible((prev) => {
      const isMenuVisible = !prev;

      if (isMenuVisible) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }

      return isMenuVisible;
    });
  }

  useEffect(() => {
    if (isMenuVisible) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [isMenuVisible]);


  /* =================== [Shrinked menu handler] =================== */
  const [isShrunk, setShrunk] = useState(false);

  useEffect(() => {
    const handler = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 100 ||
            document.documentElement.scrollTop > 100)
        ) {
          return true;
        }

        if (
          isShrunk &&
          document.body.scrollTop < 100 &&
          document.documentElement.scrollTop < 100
        ) {
          return false;
        }

        return isShrunk;
      });
    };


    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  /* =================== [Resize handler] =================== */
  useEffect(() => {
    const handler = () => {
      setIsMenuVisible(false);
    };


    window.addEventListener("resize", handler);
    return () => window.removeEventListener("resize", handler);
  }, []);

  return (
    <div>
      <header className={`bg-white flex ${isShrunk ? 'sm:py-2 py-0' : 'py-4'} transition-all ease-in-out duration-200 fixed w-full top-0 left-0 z-50 border-b border-b-i-blue ultra:max-w-[1920px] ultra:left-1/2 ultra:-translate-x-1/2`}>
        <Container>
          <Row>
            <Column className="justify-between items-center flex">
              <Link href={route('home')}>
                <ApplicationLogo className={`${isShrunk ? 'h-6' : 'xl:h-12 lg:h-10 md:h-12'} transition-all ease-in-out duration-200 w-auto`} />
              </Link>

              <nav className="lg:block hidden">
                {LinksArrayFrontDesktop.map((link: MenuLinks, index) => {
                  if (link.target == 'blank') {
                    return(
                      <a
                        href={link.url}
                        title={link.title}
                        className="text-white bg-i-gold hover:text-i-light-gray transition-all ease-in-out duration-300 xl:px-3 px-1 py-3 inline-block text-14 leading-4 font-semibold"
                        target={'_blank'}
                        key={link.title}
                      >
                        <div className="flex items-center">
                          {link.title}
                          <InlineIcon icon="el:download" className="ml-1"/>
                        </div>
                      </a>
                    )
                  } else {
                    return (
                    <Link
                      key={index}
                      href={link.url}
                      title={link.title}
                      className="text-i-gray hover:text-i-gold transition-all ease-in-out duration-300 xl:px-3 px-1 py-3 inline-block"
                    >
                      <Text heading='label' color="inherit" bold>
                        <span>{link.title}</span>
                      </Text>
                    </Link>
                    )
                  }
                })}
              </nav>

              {disableNav === false && (
                <button className="text-i-navy hover:text-i-gold p-4 lg:hidden" onClick={(e) => showNav(e)}>
                  <Icon
                    icon={"ic:round-menu"}
                    className="duration-300 ease-in-out transition-all"
                    height={24}
                    width={24}
                  />
                </button>
              )}
              {disableNav === true && (
                <img src="/storage/img/logos/unidevelopment-logo-color.svg" title="Unidevelopment" className="md:w-[280px] w-[180px]" />
              )}
            </Column>
          </Row>
        </Container>
      </header>

      {disableNav === false && (
        <NavMenu isMenuVisible={isMenuVisible} toggleMenu={showNav} links={LinksArrayFront} />
      )}

      <main className="xl:pt-20 lg:pt-18 pt-21">
        {children}
      </main>

      <Footer />
    </div>
  );
};

export default MainLayout;
