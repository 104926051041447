const LinksArrayFront = [
    {
        url: route('home'),
        title: "Strona Główna",
    },
    {
        url: route('front.discover-estates'),
        title: "Osiedle Idea",
        subNavi: [
            {
                url: route('front.discover-estates.single', 'aurora'),
                title: "Osiedle Idea Aurora",
            },
            {
                url: route('front.discover-estates.single', 'orion'),
                title: "Osiedle Idea Orion",
            },
            {
                url: route('front.discover-estates.single', 'ogrody3'),
                title: "Osiedle Idea Ogrody 3",
            },
            {
                url: route('front.discover-estates.single', 'ogrody'),
                title: "Osiedle Idea Ogrody 2",
            },
            // {
            //     url: route('front.discover-estates.single', 'alfa'),
            //     title: "Idea Alfa",
            // },
            // {
            //     url: route('front.discover-estates.single', 'omega'),
            //     title: "Idea Omega",
            // },
            // {
            //     url: route('front.discover-estates.single', 'leo'),
            //     title: "Idea Leo",
            // },
        ],
    },
    {
        url: route('front.apartments.search'),
        title: "Mieszkania",
    },
    {
        url: route('front.news'),
        title: "Aktualności",
    },
    // {
    //     url: route('front.showcase-apartment'),
    //     title: "Mieszkanie pokazowe",
    // },
    {
        url: route('front.diary-of-build'),
        title: "Dziennik budowy",
    },
    {
        url: route('front.gallery'),
        title: "Galeria",
    },
    {
        url: route('front.arrangements'),
        title: "Wnętrza",
    },
    {
        url: route('front.investor'),
        title: "Inwestor",
    },
    {
        url: route('front.contact'),
        title: "Kontakt",
    },
    {
        url: route('front.apartments'),
        title: "Lokale usługowe",
    },
    {
        url: '/storage/idea_folder.pdf',
        title: "Pobierz Folder",
        target: "blank"
    },
];

export default LinksArrayFront;
